<template>
  <footer class="footer">
    <div class="inner">
      <div class="top">
        <div class="lists">
          <div class="list" v-if="company_menu">
            <div class="heading">Company</div>
            <page-link :to="menu_page.url" v-for="menu_page in company_menu" :key="menu_page.ItemID"
              ><font-awesome-icon icon="chevron-right" /> {{ menu_page.name }}</page-link
            >
          </div>
          <div class="list" v-if="services_menu">
            <div class="heading">Services</div>
            <page-link :to="menu_page.url" v-for="menu_page in services_menu" :key="menu_page.ItemID"
              ><font-awesome-icon icon="chevron-right" /> {{ menu_page.name }}</page-link
            >
          </div>
          <div class="list" v-if="settings && settings.prices && settings.prices.length">
            <div class="heading">Prices</div>
            <a v-for="price in settings.prices" target="_blank" :href="price.file" :key="price.id"
              ><font-awesome-icon icon="chevron-right" /> {{ price.name }}</a
            >
          </div>
        </div>
        <router-link to="/" class="logo"
          ><img src="/images/hicklings-logo-contrast.png" alt="Hicklings Funeral Directors Doncaster"
        /></router-link>
      </div>
      <div class="legal">
        <div class="legal-right">
          Website designed &amp; developed by
          <a href="http://chameleoncreative.marketing" target="_blank">Chameleon Creative</a>
        </div>
        <router-link v-if="privacyPage" :to="privacyPage.url">Privacy Policy</router-link> &copy; 2022 Hicklings Funeral
        Service Registered in England &amp; Wales Number: 03691034
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page", "settings", "company_menu", "services_menu", "privacyPage"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<style scoped>
.footer {
  padding-top: 7rem;
  padding-bottom: 3rem;
  background: var(--primary-color);
  background: linear-gradient(180deg, rgba(28, 8, 84, 1) 0%, rgba(49, 23, 121, 1) 100%);
  color: white;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
}

.logo {
  display: block;
  float: right;
}

.grid {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
}

.top {
  width: 100%;
  display: table;
}

.lists {
  float: left;
  display: grid;
  width: 55%;
  grid-template-columns: 1fr 1fr 1fr;
}

.list .heading {
  color: var(--primary-color);
  color: white;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "Cinzel", "Times New Roman", Times, serif;
}

.list a {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
}

.list a svg {
  color: var(--secondary-color);
  margin: 0 0.5rem;
}

.list a.heading svg {
  margin: 0 0.1rem;
  opacity: 1;
}

.legal {
  margin-top: 8rem;
  font-size: 1.2rem;
  color: rgb(218, 218, 218);
}

.bsi {
  width: 12rem;
  opacity: 0.2;
  margin-top: 5rem;
}

.legal-right {
  float: right;
  text-align: right;
}

.footer a:hover {
  color: var(--secondary-color);
}

.cards {
  width: 30rem;
  margin-top: 5rem;
  display: block;
}

@media screen and (max-width: 1090px) {
  .lists {
    float: none;
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .legal {
    text-align: center;
  }
  .legal-right {
    float: none;
    text-align: center;
    margin-bottom: 3rem;
  }
  .logo {
    display: block;
    margin: auto;
    margin-top: 7rem;
    float: none;
    width: 50%;
  }
  .bsi,
  .cards {
    display: block;
    margin: auto;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 650px) {
  .lists {
    display: none;
  }
  .footer {
    padding-top: 0;
  }
}
</style>
