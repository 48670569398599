<template>
  <div>
    <header-elem />
    <banner-elem v-if="!isObit && !isObitsPage" />

    <content-elem v-if="!isContactPage && !isObit && !isObitsPage" :page_loaded="page_loaded" />
    <obituaries-elem v-if="isObitsPage" />
    <obituary-elem v-if="isObit" />

    <links-elem v-if="!isContactPage" />
    <testimonials-elem v-if="isHomePage" />
    <contact-elem />
    <latest-obituaries v-if="isHomePage && 1 == 2" />
    <accreditations-elem />
    <footer-elem />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import api from "@/services/api"

import HeaderElem from "@/components/Header.vue"
import BannerElem from "@/components/Banner.vue"
import ContentElem from "@/components/Content.vue"
import LinksElem from "@/components/Links.vue"
import TestimonialsElem from "@/components/Testimonials.vue"
import AccreditationsElem from "@/components/Accreditations.vue"
import LatestObituaries from "@/components/LatestObituaries.vue"
import ObituaryElem from "@/components/Obituary.vue"
import ContactElem from "@/components/Contact.vue"
import FooterElem from "@/components/Footer.vue"
import ObituariesElem from "@/components/Obituaries.vue"

export default {
  name: "page",
  components: {
    HeaderElem,
    BannerElem,
    ContentElem,
    FooterElem,
    TestimonialsElem,
    AccreditationsElem,
    ContactElem,
    ObituariesElem,
    ObituaryElem,
    LatestObituaries,
    LinksElem
  },
  data() {
    return { page_loaded: false }
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage", "isContactPage", "isObit", "isObitsPage"])
  },
  metaInfo() {
    return {
      title: this.page.title,
      meta: [
        { name: "description", content: this.page.meta_desc },
        { name: "keywords", content: this.page.meta_keywords }
      ]
    }
  },
  created: function () {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store

    if (!url) store.commit("setPage", {})

    if (route.name == "404") store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })

    if (route.name == "page-by-url") promises.push(api.getPageByURL(url))
    if (route.name == "root") promises.push(api.getHomePage())

    if (route.name == "obit") {
      promises.push(
        api.getPageByItemID(route.params.itemid).then((obit) => {
          if (!obit || !obit.ItemID) store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })
          obit.posts = []
          if (obit.candles && obit.candles.length) {
            var i = 0
            obit.candles.forEach((candle) => {
              candle._type = "candle"
              candle._index = i
              i++
            })
            obit.posts = obit.posts.concat(obit.candles)
          }
          if (obit.messages && obit.messages.length) {
            obit.messages.forEach((message) => {
              message._type = "message"
            })
            obit.posts = obit.posts.concat(obit.messages)
          }

          obit.posts.sort((a, b) => (a._timestamp > b._timestamp ? 1 : -1))
          return Promise.resolve(obit)
        })
      )
      promises.push(
        api.getPageByItemID(store.state.obitID).then((page) => {
          if (!page || !page.ItemID) store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })
          return Promise.resolve(page)
        })
      )
    }

    Promise.all(promises).then((vals) => {
      var returnedpage = vals[0]
      store.commit("setPage", returnedpage)

      if (!returnedpage || !returnedpage.ItemID)
        store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })

      this.$nextTick().then(() => {
        this.page_loaded = true

        window.onscroll = function () {
          var anims = document.getElementsByClassName("animated")
          var header = anims[0]

          if (header) {
            if (window.pageYOffset > 0) {
              header.classList.add("contrast")
            } else {
              header.classList.remove("contrast")
            }
          }
        }
      })
    })

    store.dispatch("getSettings")
    store.dispatch("getMainPages")
    store.dispatch("getObits")
  }
}
</script>
