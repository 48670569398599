<template>
  <section class="obituary content">
    <div class="inner">
      <div class="left">
        <div class="head">
          <img :alt="page.name" :src="page.image ? page.image : '/obit.jpg'" />
          <div class="titles">
            <span class="in-memory">In memory of...</span>
            <span class="name">{{ page.name }}</span>
            <span class="dates">{{ formatDate(page.date_of_birth) }}</span>
          </div>
        </div>

        <div
          class="body"
          v-html="
            page.description
              ? page.description
              : '<p>In loving memory of ' +
                page.name +
                ' who sadly passed away on ' +
                formatDateFull(page.date_of_death) +
                '</p>'
          "
        ></div>

        <div class="actions">
          <a @click.prevent="displayMessageModal()" href> <i class="fa fa-commenting"></i> Post a message </a>
          <a @click.prevent="displayCandleModal()" href> <i class="fa fa-fire"></i> Light a candle </a>
        </div>

        <div class="posts">
          <div
            :class="{ candle: post._type == 'candle' }"
            :key="post.itemID"
            class="post"
            v-for="post in page.posts.filter((post) => post.approved)"
          >
            <img
              :src="$store.state.s3 + 'Candles/' + ((post._index % 3) + 1) + '.gif'"
              alt="Candle"
              v-if="post._type == 'candle'"
            />
            <p v-if="post._type == 'message'">
              {{ post.message }}
              <br />
              <br />
              <strong>{{ post.name }}</strong>
            </p>
            <p v-if="post._type == 'candle'">
              <strong>{{ post.name }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="share block">
          Share Obituary
          <a
            :href="
              'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.hickling-funeral-service.co.uk/' +
              $route.fullPath
            "
            rel="noreferrer"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
        </div>
        <div class="funeralhead block" v-if="page.date_of_funeral">Funeral Details</div>
        <div class="funeralinfo block" v-if="page.date_of_funeral">
          <p v-if="page.date_of_funeral">
            <strong>Date:</strong>
            {{ formatDateFull(page.date_of_funeral) }}
          </p>
          <p v-if="page.time_of_funeral">
            <strong>Time:</strong>
            {{ page.time_of_funeral }}
          </p>
          <p v-if="page.location">
            <strong>Location:</strong>
            {{ page.location }}
          </p>
          <p v-if="page.wake">
            <strong>Committal:</strong>
            {{ page.wake }}
          </p>
        </div>
        <iframe
          title="Map"
          :src="
            'https://www.google.com/maps/embed/v1/place?q=' +
            encodeURI(page.location) +
            '&key=AIzaSyBLzJ48bU1oX0FYz2Dpd1L2MSojJrZnF04'
          "
          allowfullscreen
          frameborder="0"
          height="300"
          style="border: 0"
          v-if="page.location.length"
          width="100%"
        ></iframe>

        <div class="donatehead block" v-if="page.charity_url || page.donate_text">Donate</div>
        <div class="donateinfo block" v-if="page.charity_url || page.donate_text">
          <p v-if="page.donate_text">{{ page.donate_text }}</p>
          <p v-if="page.charity_url">
            <a
              :href="page.charity_url.indexOf('http') !== -1 ? page.charity_url : 'http://' + page.charity_url"
              rel="noreferrer"
              target="_blank"
              >Click here to donate</a
            >
          </p>
        </div>
      </div>
    </div>
    <modal-message
      :obitid="page.ItemID"
      :obitname="page.name"
      :show="showMessageModal"
      @close="showMessageModal = false"
    ></modal-message>
    <modal-candle
      :candlenumber="nextCandle"
      :obitid="page.ItemID"
      :obitname="page.name"
      :show="showCandleModal"
      @close="showCandleModal = false"
    ></modal-candle>
  </section>
</template>

<script>
import datetimeutil from "date-and-time"

import ModalCandle from "@/components/ModalCandle.vue"
import ModalMessage from "@/components/ModalMessage.vue"
import { mapState } from "vuex"

export default {
  name: "PageObituary",
  data: function () {
    return {
      showMessageModal: false,
      showCandleModal: false
    }
  },
  components: { ModalCandle, ModalMessage },
  computed: {
    ...mapState(["page"]),
    nextCandle: function () {
      if (this.page.candles) {
        var candles = this.page.candles.filter((candle) => candle.approved)
        if (candles.length) {
          var i = (candles[candles.length - 1]._index % 3) + 2
          if (i == 4) return (i = 1)
          return i
        } else return 1
      } else return 1
    }
  },
  methods: {
    formatDate(int) {
      return datetimeutil.format(new Date(int * 1000), "MMM YYYY")
    },
    formatDateFull(int) {
      var day = datetimeutil.format(new Date(int * 1000), "D")
      var ordinal = this.ordinal_suffix_of(day)
      var month_year = datetimeutil.format(new Date(int * 1000), "MMMM YYYY")
      return ordinal + " " + month_year
    },
    displayMessageModal: function () {
      this.showMessageModal = true
    },
    displayCandleModal: function () {
      this.showCandleModal = true
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100
      if (j == 1 && k != 11) {
        return i + "st"
      }
      if (j == 2 && k != 12) {
        return i + "nd"
      }
      if (j == 3 && k != 13) {
        return i + "rd"
      }
      return i + "th"
    }
  }
}
</script>

<style scoped>
.obituary {
  padding: 6rem 3%;
  padding-top: 20rem;
}

.left {
  width: 70%;
  float: left;
  margin-right: 4%;
}

.right {
  width: 26%;
  float: left;
  color: white;
  font-size: 2rem;
  font-family: "Cinzel", "Times New Roman", Times, serif;
}

.block,
.donatehead {
  background: var(--primary-color);
  padding: 1rem 2rem;
}

.share {
  margin-bottom: 2rem;
}

.share a {
  float: right;
  margin-left: 1rem;
  transition: all 0.2s;
}

.share a:hover {
  filter: brightness(1.1);
}

.share a i {
  display: block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  background: #229ff1;
  position: relative;
}

.share a i.fa-facebook {
  background: #3d5a97;
}

.funeralhead {
  background: var(--secondary-color);
}

.funeralinfo,
.donateinfo {
  background: #f6f6f6;
  color: #666;
  font-size: 1.5rem;
  padding-top: 2rem;
}

iframe {
  margin-bottom: 2rem;
}

.donateinfo a {
  font-weight: bold;
}

.head {
  background: #f6f6f6;
  display: table;
  width: 100%;
}

.head > img {
  float: left;
  width: 30%;
}

.titles {
  float: left;
  width: 70%;
  padding: 2rem 3rem;
  font-family: "Roboto Slab", Georgia, "Times New Roman", Times, serif;
}

.titles span {
  display: block;
}

.name {
  font-size: 4rem;
  line-height: 1.3;
  color: #333;
}

.dates {
  font-size: 2rem;
  color: var(--secondary-color);
}

.body {
  padding: 3rem 0;
  padding-bottom: 1rem;
}

.actions {
  border-top: 5px solid #f6f6f6;
  padding: 2rem 0;
}

.actions a {
  display: inline-block;
  background: #f6f6f6;
  padding: 1rem 2rem;
  margin-right: 2rem;
}

.actions a i {
  margin-right: 1rem;
  color: var(--secondary-color);
}

.actions a:hover {
  background: rgb(233, 233, 233);
}

.posts {
  display: table;
  width: 100%;
  padding-bottom: 3rem;
}

.post {
  width: 31%;
  margin-right: 2.3%;
  float: left;
  margin-top: 2rem;
  border: 1px solid rgb(233, 233, 233);
  padding: 2rem;
  padding-bottom: 0;
}

.post p {
  padding-bottom: 1rem;
}

@media screen and (max-width: 1090px) {
  .obituary {
    padding-top: 15rem;
  }
}

@media screen and (max-width: 850px) {
  .left,
  .right {
    float: none;
    width: 100%;
    margin-right: 0;
  }

  .post {
    width: 47%;
  }
}

@media screen and (max-width: 800px) {
  .obituary {
    padding-top: 12rem;
  }
}

@media screen and (max-width: 550px) {
  .head > img {
    float: none;
    width: 100%;
  }

  .titles {
    width: 100%;
  }
  .post {
    width: 100%;
    margin-right: 0;
  }
}
</style>
