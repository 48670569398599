<template>
  <header id="header" class="header" :class="{ animated: isHomePage, contrast: !isHomePage }">
    <div class="inner">
      <router-link to="/" class="logo"
        ><img src="/images/hicklings-logo.svg" alt="Hicklings Funeral Directors Doncaster"
      /></router-link>
      <div class="header-actions" v-if="settings">
        <a :href="'tel:' + settings.telephone.replace(/ /g, '')" class="call" v-if="settings.telephone"
          ><font-awesome-icon icon="phone-alt" /><span>{{ settings.telephone }}</span></a
        >
        <a :href="'mailto:' + settings.email" class="email" v-if="settings.email"
          ><font-awesome-icon icon="envelope" /><span>Email Us</span></a
        >
        <a
          href="https://maps.google.com/maps/dir//Hickling's+Funeral+Service+4-12+Church+Ln+Adwick+le+Street+Doncaster+DN6+7AQ/@53.5709669,-1.183978,11z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4879128f1c7dcd19:0xc34ce91cd8426ed3"
          target="_blank"
          rel="noreferrer"
          class="find"
          ><font-awesome-icon icon="map-marker-alt" /><span>Find Us</span></a
        >
      </div>
      <navigation-elem></navigation-elem>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex"
import NavigationElem from "@/components/Navigation.vue"

export default {
  components: { NavigationElem },
  computed: {
    ...mapGetters(["isHomePage"]),
    settings() {
      return this.$store.state.settings
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  width: 100%;
  padding: 2rem 0;
  font-family: "Cinzel", "Times New Roman", Times, serif;
  z-index: 10;
  transition: all 0.5s;
}

.header.contrast {
  background: white;
  padding-bottom: 0;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
}

.logo {
  display: block;
  width: 38rem;
  margin-bottom: -2rem;
  transition: all 0.5s;
}

.contrast .logo {
  width: 22rem;
  margin: -1rem 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
}

.header-actions {
  display: block;
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0rem;
  right: 0;
  padding: 1rem 0;
  color: var(--primary-color);
}

.header-actions a {
  background: var(--tertiary-color);
  padding: 0.5rem 2rem;
  font-size: 1.8rem;
  margin-left: 2rem;
  transition: all 0.3s;
  border-radius: 0.5rem;
}

.header-actions a:hover {
  background: var(--primary-color);
  color: white;
}

.header-actions svg {
  margin-right: 1rem;
  font-size: 1.6rem;
  color: var(--secondary-color);
}

.contrast .header-actions {
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 1090px) {
  .logo {
    position: relative;
    left: 8rem;
  }
  .contrast .logo {
    left: 6rem;
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 850px) {
  .logo {
    width: 22rem;
    left: 5rem;
  }
  .contrast .logo {
    width: 15rem;
    left: 4.5rem;
    margin-bottom: 0;
  }
  .header-actions {
    background: none;
    padding: 0;
    top: 1rem;
  }
  .header-actions span {
    display: none;
  }
  .header-actions a {
    background: none;
    padding: 1rem 0.5rem;
  }
  .header-actions svg {
    margin-right: 0;
    font-size: 2rem;
  }
}
</style>
