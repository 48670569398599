<template>
  <div class="nav-wrapper">
    <a @click.prevent="toggleMainMenu()" class="mobile-menu" href id="mobile-menu-btn" v-if="menu_pages">
      <font-awesome-icon icon="bars" v-show="!showMenu" />
      <font-awesome-icon icon="times" v-show="showMenu" />
    </a>
    <nav :class="{ open: showMenu }" id="nav" v-if="menu_pages">
      <ul class="nav-root">
        <li v-for="menu_page in menu_pages" :key="menu_page.ItemID">
          <router-link :to="'/' + menu_page.url">{{ menu_page.name }}</router-link>
        </li>
        <li v-if="settings && settings.prices && settings.prices.length">
          <a href @click.prevent>Prices</a>
          <ul>
            <li v-for="price in settings.prices" :key="price._id">
              <a target="_blank" :href="price.file">{{ price.name }}</a>
            </li>
          </ul>
        </li>
        <li v-if="contactPage">
          <page-link :to="'/' + contactPage.url">{{ contactPage.name }}</page-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      query: "",
      showMenu: false
    }
  },
  computed: {
    menu_pages() {
      return this.$store.state.menu_pages
    },
    page() {
      return this.$store.state.page
    },
    settings() {
      return this.$store.state.settings
    },
    contactPage() {
      return this.$store.state.contactPage
    }
  },
  methods: {
    toggleMainMenu() {
      this.showMenu = !this.showMenu
      document.body.classList.toggle("fixed")
    },
    toggleSubMenu: (event) => {
      var li = event.srcElement.closest("li")
      li.classList.toggle("open")
    },
    search() {
      if (!this.query) return

      this.$router.push("search-results?q=" + this.query.toLowerCase())
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  margin-top: 2rem;
  transition: all 0.5s;
}

.contrast nav {
  margin-top: 0;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1;
  white-space: nowrap;
}

nav > ul > li {
  display: inline-block;
  margin-right: 3.5%;
}

nav > ul > li:last-child {
  margin-right: 0%;
}

nav a {
  pointer-events: all;
  display: block;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  line-height: 5rem;
  transition: all 0.3s;
  white-space: nowrap;
  color: var(--primary-color);
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
  transition: all 0.5s;
}

.contrast nav a {
  font-size: 1.6rem;
}

nav ul ul a {
  padding: 1rem 1.5rem;
  line-height: 1.4;
  margin-right: 0;
  text-transform: none;
  z-index: 1;
  position: relative;
  left: -1.5rem;
}
nav ul ul li:last-child a {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.contrast ul ul a {
  background: #fff;
}

nav a:hover,
nav a.sel,
nav a.router-link-exact-active {
  color: var(--secondary-color);
}

/* Hide Dropdowns by Default */
nav ul ul {
  display: none;
  position: absolute;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  width: auto;
  float: none;
  display: list-item;
  position: relative;
}

/* Second, Third and more Tiers	*/
nav ul ul ul {
  position: absolute;
  top: 0;
  left: 100%;
}
nav .down {
  display: none;
  padding: 0.8rem 1rem;
  padding-left: 1.5rem;
  top: -0.8rem;
  right: -1rem;
  position: relative;
}

nav .down .fa-chevron-up {
  display: none;
}

.mobile-menu {
  display: none;
  position: absolute;
  left: 0;
  font-size: 4rem;
  color: var(--primary-color);
  z-index: 3;
  transition: all 0.3s;
}

.contrast .mobile-menu {
  font-size: 3rem;
}

.mobile-menu > * {
  position: absolute;
  left: 0;
  top: 3rem;
  transition: all 0.3s;
}

.contrast .mobile-menu > * {
  top: -0.5rem;
}

.mobile-menu .fa-times {
  margin-left: 0.5rem;
}

@media screen and (min-width: 1091px) {
  /* Display Dropdowns on Hover */
  .mobile {
    display: none;
  }
  nav ul li:hover > ul {
    display: inherit;
  }
  nav {
    text-transform: uppercase;
    pointer-events: all;
  }
}

@media screen and (max-width: 1250px) {
  nav > ul > li {
    margin-right: 2%;
  }

  nav a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1090px) {
  nav {
    background: white;
    position: fixed;
    top: 12.5rem;
    right: 100%;
    width: 100%;
    transition: all 0.3s;
    z-index: 9999;
    height: calc(100vh - 12.5rem);
    overflow: auto;
    padding-bottom: 8rem;
    padding-top: 10px;
  }
  .contrast nav {
    top: 8rem;
  }

  nav .down {
    display: block;
    float: right;
  }
  nav.open {
    right: 0;
  }
  nav ul {
    padding-bottom: 2rem;
  }

  .nav-root > li:first-child a {
    margin-right: 0;
    margin-left: 0;
  }
  nav ul a,
  .contrast ul a {
    padding: 1rem 3%;
    line-height: 1.2;
    font-size: 2rem;
    margin-right: 0;
  }
  nav > ul > li {
    display: block;
    margin-right: 0;
  }

  nav ul ul,
  nav ul ul ul {
    position: relative;
    top: 0;
    left: 4rem;
    width: calc(100% - 4rem);
    padding-bottom: 0;
    display: block;
  }
  nav ul ul ul {
    left: 0;
    width: 100%;
  }

  nav ul li.open > ul {
    display: block;
  }

  nav .up,
  nav .down {
    margin-right: 1.5%;
    pointer-events: none;
  }

  nav li.open > a > .down .fa-chevron-up {
    display: block;
  }

  nav li.open > a > .down .fa-chevron-down {
    display: none;
  }

  .mobile-menu {
    display: block;
    top: 1.4rem;
  }

  nav ul a {
    font-size: 1.8rem;
  }
  nav ul ul a,
  .contrast nav ul ul a {
    font-size: 1.6rem;
  }

  nav ul ul .down,
  nav ul ul .up {
    margin-right: 0.1%;
  }
  nav ul ul ul a {
    font-size: 1.4rem;
    padding-left: 4rem;
  }
}

@media screen and (max-width: 850px) {
  nav {
    top: 11rem;
    height: calc(100vh - 11rem);
  }
  .mobile-menu > * {
    top: 1rem;
  }

  .contrast .mobile-menu > * {
    top: -1rem;
  }
  .mobile-menu {
    font-size: 3rem;
  }
}

@media screen and (max-width: 500px) {
  nav {
    top: 100px;
    right: 100%;
    width: 100%;
    transition: all 0.3s;
    z-index: 9999;
    height: calc(100vh - 70px);
  }
  .contrast nav {
    top: 70px;
  }
}
</style>
