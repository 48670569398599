<template>
  <div class="contact">
    <div class="inner" v-if="settings">
      <div class="heading" v-if="settings.cta_header">{{ settings.cta_header }}</div>
      <p class="sub" v-if="settings.cta_paragraph">
        {{ settings.cta_paragraph }}
      </p>
      <div class="grid">
        <div class="tel" v-if="settings.telephone">
          <a :href="'tel:' + settings.telephone.replace(/ /g, '')"><span>Tel:</span> {{ settings.telephone }}</a>
        </div>
        <div class="email" v-if="settings.email">
          <a class="email" :href="'mailto:' + settings.email">{{ settings.email }}</a>
        </div>
        <div v-if="settings.address" class="address" v-html="settings.address.replace(/[\n\r]/g, '<br />')"></div>
        <iframe
          aria-label="Map"
          v-if="isHomePage || isContactPage"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d75818.38806045977!2d-1.2486616777343738!3d53.56982273935218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc34ce91cd8426ed3!2sHickling&#39;s%20Funeral%20Service!5e0!3m2!1sen!2suk!4v1644483741944!5m2!1sen!2suk"
          width="100%"
          height="450"
          class="map"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page", "settings"]),
    ...mapGetters(["isHomePage", "isContactPage"])
  }
}
</script>

<style scoped>
.contact {
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
  padding: 8rem 0;
  font-family: "Cinzel", "Times New Roman", Times, serif;
  color: var(--primary-color);
  background: url(/images/watermark-mono.png);
  background-position: top;
  text-align: center;
}

.heading {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 5rem;
}

.sub {
  max-width: 75rem;
  margin: auto;
  margin-bottom: 5rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 120rem;
  margin: auto;
}

.form input,
.form textarea {
  width: 100%;
  max-width: 50rem;
  padding: 1.5rem;
  font-size: 2rem;
  border: 0;
  font-family: "Cinzel", "Times New Roman", Times, serif;
  border-radius: 0;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
}

.form textarea {
  height: 33.5rem;
}

.form button {
  font-family: "Cinzel", "Times New Roman", Times, serif;
  background: var(--primary-color);
  display: inline-block;
  color: white;
  padding: 1rem 2rem;
  font-size: 2rem;
  margin-top: 3rem;
  margin-right: 2rem;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
  border: 0;
  cursor: pointer;
  border-radius: 0.5rem;
}

.map {
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
}

.row {
  margin: 2rem 0;
}

.tel {
  font-size: 4rem;
}

.tel span {
  font-size: 3rem;
  color: var(--secondary-color);
}

.email {
  font-weight: 600;
  font-size: 2.5rem;
  margin: 2rem 0;
}

.address {
  font-size: 2rem;
}

.map {
  margin-top: 5rem;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .form input,
  .form textarea {
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .email {
    font-size: 1.8rem;
  }
  .heading {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  .grid {
    text-align: center;
  }
}
</style>
