<template>
  <section class="obituaries content">
    <div class="inner">
      <h1><i class="fa fa-fire"></i> Obituaries</h1>
      <div :key="'container' + page" class="container" v-for="page in pageCount" v-show="page == currentPage">
        <div :key="obit.ItemID" class="obit-thumb" v-for="obit in pageSlice(obits, pageSize, currentPage)">
          <router-link
            :style="{ backgroundImage: 'url(' + encodeURI(obit.image ? obit.image : '/obit.jpg') + ')' }"
            :title="obit.name"
            :to="{ name: 'obit', params: { itemid: obit.ItemID, name: urlFormat(obit.name) } }"
            class="obit-img"
          ></router-link>
          <div class="obit-info">
            <h4>{{ obit.name }}</h4>
            <h5>{{ formatDate(obit.date_of_birth) }} - {{ formatDate(obit.date_of_death) }}</h5>
            <router-link :to="{ name: 'obit', params: { itemid: obit.ItemID, name: urlFormat(obit.name) } }"
              >view obituary</router-link
            >
          </div>
        </div>
      </div>
      <div class="pages">
        <a
          :class="{ sel: page == currentPage }"
          :key="'selector' + page"
          @click.prevent="currentPage = page"
          href
          v-for="page in pageCount"
          >{{ page }}</a
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import pagination from "@/utils/pagination"
import stringutil from "@/utils/formatstring"
import datetimeutil from "date-and-time"

export default {
  mixins: [pagination, stringutil],
  data: function () {
    return {
      currentPage: 1,
      pageSize: 9
    }
  },
  computed: {
    ...mapState(["obits"]),
    pageCount: function () {
      if (!this.obits) return 0
      return this.numberofpages(this.obits, this.pageSize)
    }
  },
  methods: {
    formatDate(int) {
      return datetimeutil.format(new Date(int * 1000), "MMM YYYY")
    }
  }
}
</script>

<style scoped>
.obituaries {
  padding: 15rem 3%;
  padding-top: 20rem;
}

h1 i {
  margin-right: 0.5rem;
  font-size: 2.5rem;
}

.obit-thumb {
  display: block;
  float: left;
  width: 32%;
  margin: 3rem 0;
  line-height: 0;
}

.obit-thumb:hover > .obit-img {
  filter: brightness(1.1);
  transform: scale(1.05);
}

.obit-thumb:nth-child(2) {
  margin: 3rem 2%;
}

.obit-img {
  float: left;
  width: 50%;
  height: 20rem;
  background-size: cover;
  background-position: center center;
  display: block;
  transition: all 0.2s;
}

.obit-info {
  float: left;
  width: 45%;
  padding-left: 5%;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 0;
  text-align: left;
  line-height: 1.5;
  min-height: 16rem;
}

.obit-info h4 {
  font-family: "Merriweather", serif;
  font-weight: normal;
  font-size: 2rem;
  max-height: 6rem;
  overflow: hidden;
}

.obit-info h5 {
  font-size: 1.2rem;
  color: #c4a57a;
}

.obit-info a {
  display: block;
  margin-top: 1rem;
}

.obit-thumb {
  background-color: #f5f5f5;
  margin-top: 0;
}

.obit-thumb:nth-child(2),
.obit-thumb:nth-child(5),
.obit-thumb:nth-child(8) {
  margin: 3rem 2%;
  margin-top: 0;
}

.pages {
  clear: both;
}

.pages a {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-color: #c4a57a;
  text-align: center;
  line-height: 2.9rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.pages a:hover {
  background-color: #b9996d;
}

.pages a.sel {
  background-color: #bb9765;
  color: white;
}

@media screen and (max-width: 1090px) {
  .obituary {
    padding-top: 15rem;
  }
}

@media screen and (max-width: 1100px) {
  .obit-thumb {
    display: inline-block;
    float: none;
    width: 47.5%;
    margin: 0.5rem 0 !important;
    margin-right: 2.5% !important;
  }
}

@media screen and (max-width: 600px) {
  .obit-thumb {
    width: 100%;
    margin-right: 0 !important;
  }
}
</style>
