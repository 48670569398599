<template>
  <section class="content">
    <a id="more"></a>
    <div class="inner">
      <div class="blocks" v-if="!isReviewsPage">
        <div
          class="block"
          :class="block.single_column ? 'single' : ''"
          v-for="block in page.content_blocks"
          :key="block._id"
        >
          <div class="block-one" v-if="block.text_1" v-html="block.text_1"></div>
          <div class="block-one" v-if="block.image_1">
            <img :src="block.image_1" alt="Funeral Directors Doncaster" />
          </div>
          <div class="block-two" v-if="block.text_2" v-html="block.text_2"></div>
          <div class="block-two" v-if="block.image_2">
            <img :src="block.image_2" alt="Doncaster Funeral Directors" />
          </div>
        </div>
      </div>

      <div class="full" v-if="isReviewsPage" v-html="page.main_text"></div>

      <reviews-elem v-if="isReviewsPage" />
      <gallery-elem v-if="isGalleryPage" :page_loaded="page_loaded" />
    </div>
  </section>
</template>

<script>
import GalleryElem from "@/components/Gallery.vue"
import ReviewsElem from "@/components/Reviews.vue"
import { mapState, mapGetters } from "vuex"

export default {
  props: ["page_loaded"],
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage", "isReviewsPage", "isGalleryPage"])
  },
  components: {
    GalleryElem,
    ReviewsElem
  }
}
</script>

<style scoped>
#more {
  position: absolute;
  top: -10rem;
}
.content {
  padding-bottom: 5rem;
  background: url(/images/watermark.gif);
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 2;
}

.block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text image";
  margin: 7.5rem auto;
  gap: 10rem;
}

.block.single {
  grid-template-columns: 1fr;
}

.block >>> ul,
.block >>> ol {
  line-height: 1.5;
  margin-bottom: 2rem;
}

.block >>> a {
  text-decoration: underline;
}

.full {
  margin: 10rem auto;
  margin-bottom: 5rem;
}

.block-one {
  grid-area: text;
}

.block-two {
  grid-area: image;
  position: relative;
  line-height: 0;
}

.block-two img {
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

@media screen and (max-width: 1290px) {
  .block {
    margin: 10rem auto;
    gap: 7rem;
  }
}
@media screen and (max-width: 1090px) {
  .content {
    background-size: 50rem;
  }
  .block {
    grid-template-columns: 1fr;
    grid-template-areas: unset;
    gap: 5rem;
    margin: 5rem auto;
  }
  .full {
    margin: 5rem auto;
  }

  .block-two {
    position: unset;
    grid-area: unset;
  }
  .block-one {
    grid-area: unset;
  }
  .block:nth-child(odd) .block-two {
    top: unset;
    margin-bottom: unset;
  }

  .block:nth-child(even) .block-two {
    top: unset;
    margin-bottom: unset;
  }
}

@media screen and (max-width: 650px) {
  .content {
    background-size: 50%;
  }
}
</style>
