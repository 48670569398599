<template>
  <div class="testimonials" v-if="reviewsPage && reviewsPage.reviews">
    <div class="inner">
      <div class="header">Kind words from our customers</div>
      <div class="slides">
        <slide-show @slide-change="pagenumber = $event" ref="slideshow">
          <div class="slide" v-for="slide in randomTestimonials" :key="slide._id">
            <div class="quote" v-html="slide.quote"></div>
            <div class="person">{{ slide.name }}</div>
          </div>
        </slide-show>

        <div class="pager">
          <a
            aria-label="Review pager"
            v-for="(slide, i) in randomTestimonials"
            href
            :key="i"
            :class="{ sel: i + 1 == pagenumber }"
            @click.prevent="$refs.slideshow.show(i + 1)"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import SlideShow from "./elements/SlideShow.vue"

export default {
  components: { SlideShow },
  data: function () {
    return { pagenumber: 1 }
  },
  computed: {
    ...mapState(["page", "reviewsPage"]),
    ...mapGetters(["isHomePage", "isContactPage"]),
    randomTestimonials() {
      if (this.reviewsPage && this.reviewsPage.reviews) {
        var arr = this.reviewsPage.reviews
        var shuffled = arr.sort((a, b) => b.quote.length - a.quote.length)
        var selected = shuffled.slice(0, 5)
        return selected
      }

      return null
    }
  }
}
</script>

<style scoped>
.testimonials {
  text-align: center;
  padding-bottom: 7rem;
  padding-top: 8rem;
  font-family: "Cinzel", "Times New Roman", Times, serif;
  background: #faf4e8;
}
.header {
  font-size: 4rem;
  margin-bottom: 5rem;
  text-align: center;
  font-weight: 600;
  color: var(--primary-color);
}

.slides {
  text-align: center;
  width: 100%;
  max-width: 120rem;
  margin: auto;
  line-height: 1.8;
  font-size: 2rem;
}
.quote p,
.slide,
.quote {
  width: 100%;
}
.person {
  color: var(--primary-color);
  font-weight: 600;
}
.pager {
  bottom: -3rem;
  position: relative;
  z-index: 10;
}
.pager a {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--primary-color);
  margin: 0 0.5rem;
  border-radius: 100%;
  cursor: pointer;
}
.pager a.sel {
  background: var(--secondary-color);
}

@media screen and (max-width: 500px) {
  .header {
    font-size: 3rem;
  }
}
</style>
