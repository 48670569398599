var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"obituary content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"head"},[_c('img',{attrs:{"alt":_vm.page.name,"src":_vm.page.image ? _vm.page.image : '/obit.jpg'}}),_c('div',{staticClass:"titles"},[_c('span',{staticClass:"in-memory"},[_vm._v("In memory of...")]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.page.name))]),_c('span',{staticClass:"dates"},[_vm._v(_vm._s(_vm.formatDate(_vm.page.date_of_birth)))])])]),_c('div',{staticClass:"body",domProps:{"innerHTML":_vm._s(
          _vm.page.description
            ? _vm.page.description
            : '<p>In loving memory of ' +
              _vm.page.name +
              ' who sadly passed away on ' +
              _vm.formatDateFull(_vm.page.date_of_death) +
              '</p>'
        )}}),_c('div',{staticClass:"actions"},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.displayMessageModal()}}},[_c('i',{staticClass:"fa fa-commenting"}),_vm._v(" Post a message ")]),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.displayCandleModal()}}},[_c('i',{staticClass:"fa fa-fire"}),_vm._v(" Light a candle ")])]),_c('div',{staticClass:"posts"},_vm._l((_vm.page.posts.filter(function (post) { return post.approved; })),function(post){return _c('div',{key:post.itemID,staticClass:"post",class:{ candle: post._type == 'candle' }},[(post._type == 'candle')?_c('img',{attrs:{"src":_vm.$store.state.s3 + 'Candles/' + ((post._index % 3) + 1) + '.gif',"alt":"Candle"}}):_vm._e(),(post._type == 'message')?_c('p',[_vm._v(" "+_vm._s(post.message)+" "),_c('br'),_c('br'),_c('strong',[_vm._v(_vm._s(post.name))])]):_vm._e(),(post._type == 'candle')?_c('p',[_c('strong',[_vm._v(_vm._s(post.name))])]):_vm._e()])}),0)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"share block"},[_vm._v(" Share Obituary "),_c('a',{attrs:{"href":'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.hickling-funeral-service.co.uk/' +
            _vm.$route.fullPath,"rel":"noreferrer","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook']}})],1)]),(_vm.page.date_of_funeral)?_c('div',{staticClass:"funeralhead block"},[_vm._v("Funeral Details")]):_vm._e(),(_vm.page.date_of_funeral)?_c('div',{staticClass:"funeralinfo block"},[(_vm.page.date_of_funeral)?_c('p',[_c('strong',[_vm._v("Date:")]),_vm._v(" "+_vm._s(_vm.formatDateFull(_vm.page.date_of_funeral))+" ")]):_vm._e(),(_vm.page.time_of_funeral)?_c('p',[_c('strong',[_vm._v("Time:")]),_vm._v(" "+_vm._s(_vm.page.time_of_funeral)+" ")]):_vm._e(),(_vm.page.location)?_c('p',[_c('strong',[_vm._v("Location:")]),_vm._v(" "+_vm._s(_vm.page.location)+" ")]):_vm._e(),(_vm.page.wake)?_c('p',[_c('strong',[_vm._v("Committal:")]),_vm._v(" "+_vm._s(_vm.page.wake)+" ")]):_vm._e()]):_vm._e(),(_vm.page.location.length)?_c('iframe',{staticStyle:{"border":"0"},attrs:{"title":"Map","src":'https://www.google.com/maps/embed/v1/place?q=' +
          encodeURI(_vm.page.location) +
          '&key=AIzaSyBLzJ48bU1oX0FYz2Dpd1L2MSojJrZnF04',"allowfullscreen":"","frameborder":"0","height":"300","width":"100%"}}):_vm._e(),(_vm.page.charity_url || _vm.page.donate_text)?_c('div',{staticClass:"donatehead block"},[_vm._v("Donate")]):_vm._e(),(_vm.page.charity_url || _vm.page.donate_text)?_c('div',{staticClass:"donateinfo block"},[(_vm.page.donate_text)?_c('p',[_vm._v(_vm._s(_vm.page.donate_text))]):_vm._e(),(_vm.page.charity_url)?_c('p',[_c('a',{attrs:{"href":_vm.page.charity_url.indexOf('http') !== -1 ? _vm.page.charity_url : 'http://' + _vm.page.charity_url,"rel":"noreferrer","target":"_blank"}},[_vm._v("Click here to donate")])]):_vm._e()]):_vm._e()])]),_c('modal-message',{attrs:{"obitid":_vm.page.ItemID,"obitname":_vm.page.name,"show":_vm.showMessageModal},on:{"close":function($event){_vm.showMessageModal = false}}}),_c('modal-candle',{attrs:{"candlenumber":_vm.nextCandle,"obitid":_vm.page.ItemID,"obitname":_vm.page.name,"show":_vm.showCandleModal},on:{"close":function($event){_vm.showCandleModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }