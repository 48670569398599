<template>
  <section>
    <div class="inner">
      <div class="grid">
        <page-link :to="servicesPage.url" v-if="servicesPage">
          <span :title="servicesPage.name" class="image"></span>
          <span class="info">
            <span class="name">Our Services</span>
            <span class="date">From funeral vehicles to floral tributes, we've got you covered</span>
          </span>
        </page-link>
        <page-link :to="prepaidPage.url" v-if="prepaidPage">
          <span :title="prepaidPage.name" class="image"></span>
          <span class="info">
            <span class="name">Pre-Paid Funeral Plans</span>
            <span class="date">Competitive funeral packages for all budgets from Golden Charter</span>
          </span>
        </page-link>
        <page-link :to="informationPage.url" v-if="informationPage">
          <span :title="informationPage.name" class="image"></span>
          <span class="info">
            <span class="name">Helpful Information</span>
            <span class="date">Useful facts and contacts to guide you through your time of loss</span>
          </span>
        </page-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["servicesPage", "prepaidPage", "informationPage"]),
    ...mapGetters(["isHomePage"])
  },
  data() {
    return { obituaries: [] }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  text-align: center;
  background: #ececf4;
  font-family: "Cinzel", "Times New Roman", Times, serif;
  color: var(--primary-color);
  margin-top: 8rem;
}

.header {
  font-size: 4rem;
  margin-bottom: 5rem;
  text-align: center;
  font-weight: 600;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  transition: all 0.2s;
  grid-gap: 3rem;
  padding-bottom: 4rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    width: 23%;
    float: left;
    margin-right: 2%;
  }
}

.info {
  padding: 1rem 2rem;
  display: block;
}

.image {
  height: 40rem;
  display: block;
  background-image: url(../assets/coffin.jpg);
  background-size: cover;
  background-position: center center;
  transition: all 0.3s;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

a:nth-child(2) .image {
  background-image: url(../assets/plan.jpg);
}

a:nth-child(3) .image {
  background-image: url(../assets/info.jpg);
}

.grid a {
  transition: all 0.2s;
  padding-bottom: 1rem;
  background: white;
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
  border-radius: 0.5rem;
  position: relative;
  top: -8rem;
}

.grid a:hover {
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.1);
  top: -10rem;
}

.grid a:hover .image {
  filter: brightness(1.05);
}

.name {
  display: block;
  font-size: 2.5rem;
}

.date {
  display: block;
  color: #999;
  font-size: 1.5rem;
}

@media screen and (max-width: 750px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>
