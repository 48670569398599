<template>
  <div class="accreditations">
    <div class="inner">
      <img
        :src="settings.accreditations_image"
        v-if="settings && settings.accreditations_image"
        alt="Our funeral director accreditations"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["settings"])
  }
}
</script>

<style scoped>
.accreditations {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 3rem;
}
</style>
