<template>
  <section class="reviews">
    <div class="grid">
      <div class="review" v-for="review in page.reviews" :key="review._id">
        <div class="review-content" v-html="review.quote"></div>
        <div class="review-name">{{ review.name }}</div>
        <div class="review-stars">
          <font-awesome-icon icon="star" v-for="star in parseInt(review.stars)" :key="star" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<style scoped>
.reviews {
  padding-bottom: 10rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;
  row-gap: 5rem;
}

.review {
}

.review-name {
  font-weight: bold;
  margin: 1rem 0;
}
.review-stars {
  color: var(--secondary-color);
}

@media screen and (max-width: 1090px) {
  .reviews {
    padding-bottom: 10rem;
  }
  .grid {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
}
</style>
