<template>
  <div class="banner" :class="{ home: isHomePage }" :style="{ backgroundImage: 'url(' + page.banner_image + ')' }">
    <template v-if="isHomePage">
      <div class="inner">
        <div class="caption">
          <h1>{{ page.caption_line_1 }}</h1>
          <h2>{{ page.caption_line_2 }}</h2>
          <div>
            <page-link v-if="servicesPage" :to="servicesPage.url">Our services</page-link>
            <page-link v-if="contactPage" :to="contactPage.url">Contact Us</page-link>
          </div>
        </div>
      </div>
      <!--<div class="pattern"></div>-->
      <div class="cta" v-if="settings">
        <div class="inner">
          <span class="help">{{ page.caption_line_3 }}</span>
          <span class="call"
            >Call us on: <a :href="'tel:' + settings.telephone.replace(/ /g, '')">{{ settings.telephone }}</a></span
          >
          <span class="or">or</span>
          <span class="email"
            ><span>email: </span><a :href="'mailto:' + settings.email">{{ settings.email }}</a></span
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page", "settings", "contactPage", "servicesPage"]),
    ...mapGetters(["isHomePage", "isReviewsPage", "isGalleryPage"])
  }
}
</script>

<style scoped>
.banner {
  height: 35vh;
  width: 100%;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: bottom right;
  font-family: "Cinzel", "Times New Roman", Times, serif;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-bottom: 1.5rem solid var(--secondary-color);
}

.banner.home {
  padding-top: 40vh;
  height: 100vh;
  margin-bottom: 2rem;
}

.pattern {
  background: url(/images/watermark.png);
  position: absolute;
  bottom: -22rem;
  left: -10rem;
  width: 50rem;
  height: 50rem;
  background-size: cover;
  z-index: 1;
}

.banner h1 {
  font-weight: 400;
  font-size: 6rem;
  color: var(--secondary-color);
  line-height: 1.2;
  margin-bottom: 2rem;
}

.banner h2 {
  font-weight: 400;
  line-height: 1.2;
  font-size: 3.5rem;
  color: var(--primary-color);
}

.caption {
  max-width: 90rem;
  position: relative;
  z-index: 2;
}

.caption a {
  background: var(--primary-color);
  display: inline-block;
  color: white;
  padding: 1rem 2rem;
  font-size: 2rem;
  margin-top: 3rem;
  margin-right: 2rem;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
}

.caption a:first-child {
  background: var(--secondary-color);
}

.cta {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--secondary-color);
  padding: 2rem 0;
  color: white;
  font-size: 2rem;
}

.cta .help {
  color: var(--primary-color);
  font-weight: 600;
}

.cta .call a {
  font-size: 2.5rem;
}

.cta span {
  margin-right: 2rem;
}

.cta .email span {
  margin-right: 0.2rem;
}

.cta a {
  font-weight: 600;
}

@media screen and (max-width: 850px) {
  .banner {
    background-position: 75% bottom;
    padding-top: 25vh;
    height: 20vh;
  }
  .banner.home {
    height: 100vh;
  }
  .banner h1 {
    font-size: 5rem;
  }
  .banner h2 {
    font-size: 3rem;
  }
  .cta span {
    display: block;
    margin-right: 0;
  }
  .cta span.or,
  .cta .email span {
    display: none;
  }

  .pattern {
    bottom: -11.5rem;
    left: -10rem;
    width: 35rem;
    height: 35rem;
  }
}

@media screen and (max-width: 500px) {
  .banner h1 {
    font-size: 4rem;
  }
  .banner h2 {
    font-size: 3rem;
  }
}
</style>
