<template>
  <section v-if="this.obits && this.obits.length">
    <div class="inner">
      <div class="header">Latest Obituaries</div>
      <slide-show>
        <div class="slide">
          <div class="grid" :key="page" v-for="page in pageCount" v-show="page == currentPage">
            <router-link
              :to="{ name: 'obit', params: { itemid: obit.ItemID, name: urlFormat(obit.name) } }"
              :key="obit.ItemID"
              v-for="obit in pageSlice(obits, pageSize, currentPage)"
            >
              <span :title="obit.name" class="image" :style="{ backgroundImage: 'url(' + obit.image + ')' }"></span>
              <span class="info">
                <span class="name">{{ obit.name }}</span>
                <span class="date">{{ formatDate(obit.date_of_birth) }} - {{ formatDate(obit.date_of_death) }}</span>
              </span>
            </router-link>
          </div>
        </div>
      </slide-show>
      <div class="controls" v-if="pageCount > 1">
        <a class="next" href @click.prevent="prevPage">
          <font-awesome-icon icon="chevron-right" />
        </a>
        <a class="prev" href @click.prevent="nextPage">
          <font-awesome-icon icon="chevron-left" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import pagination from "@/utils/pagination"
import stringutil from "@/utils/formatstring"
import datetimeutil from "date-and-time"

export default {
  mixins: [pagination, stringutil],
  data: function () {
    return {
      currentPage: 1,
      pageSize: 4
    }
  },
  computed: {
    ...mapState(["obits"]),
    pageCount: function () {
      if (!this.obits) return 0
      return this.numberofpages(this.obits, this.pageSize)
    }
  },
  methods: {
    formatDate(int) {
      return datetimeutil.format(new Date(int * 1000), "MMM YYYY")
    },
    prevPage() {
      if (this.currentPage == 1) this.currentPage = this.pageCount
      else this.currentPage -= 1
    },
    nextPage() {
      if (this.currentPage == this.pageCount) this.currentPage = 1
      else this.currentPage++
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  padding: 8rem 0;
  text-align: center;
  background: #f7f7f7;
  font-family: "Cinzel", "Times New Roman", Times, serif;
  color: var(--primary-color);
}

.header {
  font-size: 4rem;
  margin-bottom: 5rem;
  text-align: center;
  font-weight: 600;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow: hidden;
  transition: all 0.2s;
  grid-gap: 3rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    width: 23%;
    float: left;
    margin-right: 2%;
  }
}

.info {
  padding: 1rem 2rem;
  display: block;
}

.image {
  height: 30rem;
  display: block;
  background-size: cover;
  background-position: center center;
  transition: all 0.2s;
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
}

.grid a {
  transition: all 0.2s;
  padding-bottom: 1rem;
}

.grid a:hover .image {
  filter: brightness(1.05);
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.1);
}

.name {
  display: block;
  font-size: 2.5rem;
}

.slide {
  width: 100%;
}

.date {
  display: block;
  color: #999;
  font-size: 1.5rem;
}

.controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  pointer-events: none;
  z-index: 3;
}

.next {
  float: right;
  transform: translateX(50%);
}

.prev {
  transform: translateX(-50%);
}

.controls a {
  pointer-events: all;
  position: relative;
  color: var(--secondary-color);
  display: block;
  width: 5rem;
  height: 5rem;
  background: white;
  border-radius: 100%;
  line-height: 4.9rem;
  text-align: center;
  font-size: 2rem;
  transition: all 0.2s;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
}

.controls a:hover {
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 750px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .controls {
    display: none;
  }

  .next {
    transform: none;
  }

  .prev {
    transform: none;
  }
}

@media screen and (max-width: 500px) {
  .header {
    font-size: 3rem;
  }
}
</style>
